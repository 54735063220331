<template>
  <div class="ui grid container-3" id="crvls">
    <div class="side-circle top"></div>
    <div class="div-1">
      <h1><span>Our</span> Core Values</h1>
      <p>
        We believe in  <span>inclusivity</span>, because financial technology should be accessible for everyone.
      </p>
      <p>
        We believe in the <span>strength of partnership and community building</span> as both are pillars of growth.
      </p>
      <p>
        We believe in <span>progress through transformation</span> as our commitment to quality drives us to seek out technological innovations that simplify everyday transactions.
      </p>
      <p>
        We believe in <span>prudent and ethical corporate governance</span> which is our foundation to protect the trust and confidence in our brand and product.
      </p>
      <i>
        Learn more about TayoCash's commitment to consumer protection, risk management, and regulatory compliance <a  class="link" href="/termsandconditions">here</a>.
      </i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'content3'
}
</script>

<style scoped lang='scss'>
</style>
